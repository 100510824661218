import layout from '@/layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: '/loading',
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/view/login/loading.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/view/login/redirect.vue'),
  },
  {
    path: '/import',
    name: 'import',
    component: layout,
  },
  // 路由
  {
    path: '/import/exchange-collection',
    name: 'importExchangeCollection',
    meta: {
      title: '外汇归集',
    },
    component: layout,
    children: [
      {
        path: '/import/exchange-quota',
        name: 'importExchangeQuota',
        meta: {
          title: '汇入汇款',
        },
        component: () => import('@/view/import/exchangeCollection/exchangeQuota/index.vue'),
      },
    ]
  },
  {
    path: '/import/customer-manage',
    name: 'importCustomerManage',
    meta: {
      title: '客户管理',
    },
    component: layout,
    children: [
      {
        path: '/import/customer-list',
        name: 'importCustomerList',
        meta: {
          title: '客户列表',
        },
        component: () => import('@/view/import/CustomerManage/CustomerList/index.vue'),
      },
    ]
  },
  {
    path: '/import/rights-manage',
    name: 'importRightsManage',
    meta: {
      title: '权限管理',
    },
    component: layout,
    children: [
      {
        path: '/import/user-list',
        name: 'importUserList',
        meta: {
          title: '用户列表',
        },
        component: () => import('@/view/import/RightsManage/UserList/index.vue'),
      },
      {
        path: '/import/role-list',
        name: 'importRoleList',
        meta: {
          title: '角色列表',
        },
        component: () => import('@/view/import/RightsManage/RoleList/index.vue'),
      },
      {
        path: '/import/audit-logs',
        name: 'importAuditLogs',
        meta: {
          title: '审计日志',
        },
        component: () => import('@/view/import/RightsManage/AuditLogs/index.vue'),
      },
    ]
  },
  {
    path: '/import/import/app-monitor',
    name: 'importAppMonitor',
    meta: {
      title: '应用监控',
    },
    component: layout,
    children: [
      {
        path: '/import/monitor-records',
        name: 'importMonitorRecords',
        meta: {
          title: '监控记录',
        },
        component: () => import('@/view/import/AppMonitor/MonitorRecords/index.vue'),
      },
      {
        path: '/import/app-access',
        name: 'importAppAccess',
        meta: {
          title: '应用接入',
        },
        component: () => import('@/view/import/AppMonitor/AppAccess/index.vue'),
      }
    ]
  },
];

export default routes;