import { defineStore } from 'pinia'
import { useCache } from '@/hooks/useCache.js'
import { getAuthSide, initReturnAuth } from '@/utils/index.js'

const { wsCache } = useCache()
export const useMenu = defineStore('menu', {
  state: () => {
    return {
      // 目录
      importMenu: [
        {
          name: 'importExchangeCollection',
          title: '外汇归集',
          children: [
            {
              title: '汇入汇款',
              name: 'importExchangeQuota',
            },
          ],
        },
        {
          name: 'importCustomerManage',
          title: '客户管理',
          children: [
            {
              title: '客户列表',
              name: 'importCustomerList',
            },
          ],
        },
        {
          name: 'importRightsManage',
          title: '权限管理',
          children: [
            {
              title: '用户列表',
              name: 'importUserList',
            },
            {
              title: '角色列表',
              name: 'importRoleList',
            },
            {
              title: '审计日志',
              name: 'importAuditLogs',
            },
          ],
        },
        {
          name: 'importAppMonitor',
          title: '应用监控',
          children: [
            {
              title: '监控记录',
              name: 'importMonitorRecords',
            },
            {
                title: '接入管理',
                name: 'importAppAccess',
            },
          ],
        },
      ],
      activeIndex: 0,
      activeMenu: [],
      reloadMenu:[],
      transformParams: {
        custom: 'importExchangeCollection',
        remittance: 'importExchangeQuota',
        ent: 'importCustomerManage',
        enterprise: 'importCustomerList',
        auth: 'importRightsManage',
        user: 'importUserList',
        character: 'importRoleList',
        log: 'importAuditLogs',
        system_log: 'importAppMonitor',
        // system_log: 'importAppAccess',
        custom_log: 'importMonitorRecords',
        app_access: 'importAppAccess',
      }
    }
  },
  // 相当于计算属性
  getters: {},
  //写异步或同步函数
  actions: {
    async setImportMenu(data) {
      let SideMenu = this.getSide(data)
      wsCache.set('SideMenu', SideMenu)
      return true
    },
    async setRefundMenu() {
      wsCache.set('SideMenu', this.ExportTaxRefundMenu)
      return true
    },
    async setCollectMenu() {
      wsCache.set('SideMenu', this.ExportCollectMenu)
      return true
    },
    // 增加tabs
    pushMenu(val) {
      this.activeMenu.push(val)
    },
    // 删除tabs
    deleteMenu(val) {
      this.activeMenu = this.activeMenu.filter((item) => item.name != val)
    },
    // 清空tabs栏
    clearAll() {
      this.activeIndex = 0
      this.activeMenu = []
    },
    reloadStart(comp){
      this.reloadMenu.push(comp)
      setTimeout(() => {
        this.reloadMenu = []
      }, 100);
    },
    getSide(data){
      let sideMenu = getAuthSide(data)
      initReturnAuth()
      let newAuth= []
      sideMenu.map(item => newAuth.push(this.transformParams[item]))
      // 重新组装新的侧边栏
      let cacheArr = []
      this.importMenu.map((item, index) => {
        if(newAuth.includes(item.name)){
          if(item?.children){
            cacheArr[index] = JSON.parse(JSON.stringify(item))
            cacheArr[index].children = []
            item?.children.map((itemChild) => {
              if(newAuth.includes(itemChild.name)){
                cacheArr[index]?.children.push(itemChild)
              }
            })
          }else{
            cacheArr[index] = JSON.parse(JSON.stringify(item))
          }
        }
      })
      return [...cacheArr].filter(item => item !== '' ||  item !== null || item !== undefined)
    }
  },
})
